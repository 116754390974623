// Override default variables before the import
$primary: #282c34;
$secondary: #ffdead;
h1, h2, div, p, textarea {
  color: $secondary;
}

textarea {
  background-color: $primary;
}

// Import Bootstrap and its default variables
@import '/../node_modules/bootstrap/scss/bootstrap.scss';

//define with default variables
h6 {
  color: $light;
}